
import { Category } from '@/models';
import { CategoryEditRoute, CategoryScenariosRoute, HomeRoute } from '@/router';
import { ApiService, LoaderService, UserService } from '@/services';
import { defineComponent, onMounted, ref } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import Badge from 'primevue/badge';

import { useToast } from 'primevue/usetoast';
import { computed } from '@vue/reactivity';
import { CategoryAddRoute } from '@/router';

interface CategoryView extends Category {
	loading?: boolean;
}

export default defineComponent({
	components: { DataTable, Column, Message, Badge },
	setup() {
		const categories = ref<CategoryView[]>([]);
		const loadToken = LoaderService.invoke();
		const router = useRouter();
		const toast = useToast();
		const isAdmin = UserService.isAdmin;
		const newCategoriesMessage = computed(() => {
			const count = categories.value.filter(c => c.newAddition).length;
			switch (count) {
				case 0:
					return null;
				case 1:
					return `We've added a new category! Enable it below to see
		it in your future games.`;
				default:
					return `We've added ${count} new categories! Enable them below to see
		them in your future games.`;
			}
		});

		onMounted(async () => {
			try {
				categories.value = await ApiService.get<Category[]>('/categories');
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to load categories. Try again later.',
					life: 3000
				});

				router.push({ name: HomeRoute });
			} finally {
				LoaderService.clear(loadToken);
			}
		});

		const toggle = async (category: CategoryView) => {
			category.loading = true;

			const othersEnabled = categories.value.some(
				e => e.id !== category.id && e.enabled
			);

			if (category.enabled && !othersEnabled) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Must have at least one category enabled.',
					life: 3000
				});

				return;
			}

			try {
				const updated = await ApiService.post<Category>(
					`/categories/${category.id}/toggle`
				);
				Object.assign(category, updated);
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to toggle category. Try again later.',
					life: 3000
				});
			} finally {
				category.loading = false;
			}
		};

		const handleRowClass = (category: Category) => {
			if (isAdmin.value) return '';
			return category.enabled ? '' : 'disabled';
		};

		const editRoute = (categoryId: number): RouteLocationRaw => ({
			name: CategoryEditRoute,
			params: { categoryId }
		});

		const scenariosRoute = (categoryId: number): RouteLocationRaw => ({
			name: CategoryScenariosRoute,
			params: { categoryId }
		});

		return {
			categories,
			newCategoriesMessage,
			isAdmin,
			CategoryAddRoute,
			toggle,
			handleRowClass,
			editRoute,
			scenariosRoute
		};
	}
});
